<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数据列表</span>
        <!-- 				<div class="stu">
					<div class="count">{{authStuNum}}</div>
					<div>学生认证人数</div>
				</div>
				<div class="patriarch">
					<div class="count">{{authGuaNum}}</div>
					<div>家长认证人数</div>
				</div> -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button type="primary" @click="excelChu">导出数据</el-button>
      </div>
      <div class="query">
        <div class="queryLeft">
          <div style="float: left ;width: fit-content;" v-if=" Status == 'school' ? true :false">
            年级：
            <el-select v-model="gradeid" @change="classSelect()" clearable style="width: 150px;">
              <el-option v-for="(item,index) in gradeList" :key="index" :label="item.TypeName" :value="item.id">
              </el-option>
            </el-select>
          </div>&nbsp;&nbsp;
          <div style="float: left;width: fit-content;" v-if=" Status == 'school' ? true :false">
            班级：
            <el-select v-model="classid" clearable style="width: 150px;">
              <el-option v-for="(item,index) in classList" :key="index" :label="item.ClassName" :value="item.id">
              </el-option>
            </el-select>
          </div>&nbsp;&nbsp;
          <div style="width: 260px;">
            <i class="el-icon-search" style="width: 20px;"></i>
            <el-input class="select" v-model.trim="stuname" placeholder="清输入学生姓名">
            </el-input>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="studentSearchfirst(1)">查询</el-button>
        </div>
      </div>

      <el-table :data="ClassInfos" v-loading="loading">
        <el-table-column label="姓名" prop="StuName" width="150" align="center">
          <!-- 					<template slot-scope="scope">
						<el-button type="text" size="small" @click="StuNames(scope.$index, scope.row)">
							{{scope.row.StuName}}
						</el-button>
					</template> -->
        </el-table-column>
        <el-table-column prop="StuGender" label="性别" width="50" align="center" :formatter="Gender"> </el-table-column>
        <el-table-column prop="StuNumber" label="编号" align="center"> </el-table-column>
        <el-table-column label="年级班级" align="center">
          <template slot-scope="scope">
            {{scope.row.TypeName}}{{scope.row.ClassName}}
          </template>
        </el-table-column>
        <el-table-column prop="LastTestTime" sortable width="160" label="最后一次测试时间" align="center"></el-table-column>
        <el-table-column prop="LastTestLEye" label="最后一次裸眼视力(左)" align="center"></el-table-column>
        <el-table-column prop="lbgnum" label="裸眼视力(左)上一次差值" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lbgc == 1" style="color: #13CE66;">{{scope.row.lbgnum}}</span>
            <span v-else-if="scope.row.lbgc == 2" style="color: red;">{{scope.row.lbgnum}}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column prop="LastTestREye" label="最后一次裸眼视力(右)" align="center"></el-table-column>
        <el-table-column prop="rbgnum" label="裸眼视力(右)上一次差值" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.rbgc == 1" style="color: #13CE66;">{{scope.row.rbgnum}}</span>
            <span v-else-if="scope.row.rbgc == 2" style="color: red;">{{scope.row.rbgnum}}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="StuNames(scope.$index, scope.row)">查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    classStudentLis,
    classStudentRemove,
    luoeyeExcelchu,
    gradeClass
  } from '@/api/TeacherApi.js';
	import {
		mapGetters
	} from "vuex";
  export default {
    computed: {
    	...mapGetters(["Status"]),
    },
    data() {
      return {
        ClassInfos: [],
        Switch_on: false,
        authGuaNum: '', //家长认证人数
        authStuNum: '', //学生认证人数
        loading: false, //加载框
        pagesize: 12, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        SearchName: '', // 学生姓名查询
        stuname: '', //搜索学生名
        gradeid: '', //年级查询id
        gradeList: [], //年级数组
        classid: '', //班级查询id
        classList: [], // 班级数组
        gradeidsearch  : '',
        classidsearch  : '',
      }
    },
    mounted() {
      this.StudentList(1) // 获取学生列表
      this.gradeListvue() // 获取年级信息
    },
    methods: {
      reset() { //重置按钮
        this.classList = [];
        this.gradeid = '';
        this.classid = '';
        this.stuname = '';
        this.SearchName     = '';
        this.gradeidsearch  = '';
        this.classidsearch  = '';
        this.StudentList(this.currpage);
      },
      StudentList(currpage) { // 学生列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize, //	每页条数
          type: 1 //
        }
        classStudentLis(data).then(res => {
          this.ClassInfos = res.data.List;
          this.authGuaNum = res.data.authGuaNum; //家长认证人数
          this.authStuNum = res.data.authStuNum; //学生认证人数
          this.CountNum = parseInt(res.data.CountNum); // 总条数
          this.loading = false;
        }).catch(err => {
          console.log(err)
        })
      },
      Gender(row, column) { // 筛选性别
        return row.StuGender == 1 ? '男' : '女'
      },
      StuNames(index, row) { // 点击学生姓名
        //console.log(row);
        this.$router.push({ //页面跳转
          name: 'eyesight', //往这个页面跳转
          params: { //跳转传递的参数
            StuID: row.StuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
            StuName: row.StuName,
            StuSex: row.StuGender,
          }
        })
      },
      goPatriarchDetail(id) { //跳转去家长详情页
        this.$router.push({
          name: 'PatriarchDetail',
          params: {
            studentId: id
          }
        })
      },
      handleCurrentChange(val) {
        //console.log(`当前页: ${val}`);
        if (this.SearchName == "" && this.gradeidsearch=="" && this.classidsearch=="") {
          this.StudentList(val)
          this.currpage = val
        } else {
          this.studentSearch(val) //当有姓名条件时 分页操作
        }
      },
      studentSearchfirst(val) { //只有当点击查询时候走这个方法

        this.SearchName     = this.stuname;
        this.gradeidsearch  = this.gradeid;
        this.classidsearch  = this.classid;
        this.currpage = 0;

        const data = {
          StuName : this.SearchName,
          gid     : this.gradeidsearch,
          cid     : this.classidsearch,
          page    : val,
          limit   : this.pagesize
        }

        classStudentLis(data).then(res => {
          this.currpage = 1;
          this.ClassInfos = res.data.List;
          this.CountNum = parseInt(res.data.CountNum);
          this.loading = false;
        }).catch(err => {
          console.log(err)
        })
      },

      studentSearch(val) {   //当第一次 点了查询条件后 分页走这个方法
        this.currpage = val
        const data = {
          StuName: this.SearchName,
          gid: this.gradeidsearch,
          cid: this.classidsearch,
          page:  this.currpage,
          limit: this.pagesize
        }

        classStudentLis(data).then(res => {
          this.ClassInfos = res.data.List;
          this.CountNum = parseInt(res.data.CountNum);
          this.loading = false;
        }).catch(err => {
          console.log(err)
        })
      },
      excelChu() { // 导出学生信息
        const data = {
          id: 1,
          gid: this.gradeidsearch,
          cid: this.classidsearch
        }
        luoeyeExcelchu(data).then(res => {

          if (res.status.code == 1) {
            //window.open('http://localhost:8788/' + res.data);//本地
            //window.open('http://shiyun.dljianwei.com' + res.data);//测试
            // window.open('http://sygp.shiyuntech.net' + res.data); //线上
            window.open(this.$url + res.data)
          } else {
            this.$message.error(res.status.msg)
          }

        }).catch(err => {
          console.log(err)
        })
      },
      gradeListvue() { // 年级列表
        const data = {
          type: 1 //没用
        }
        gradeClass(data).then(res => {
          this.gradeList = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      classSelect() { // 班级列表
        this.classid = ''
        this.classList = []

        const data = {
          gid: this.gradeid
        }

        if (this.gradeid != "") {
          gradeClass(data).then(res => {
            this.classList = res.data;
          }).catch(err => {
            console.log(err)
          })
        }

      },
    }

  }
</script>

<style lang="scss" scoped>
  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
